import React from 'react'
import {Link} from 'react-router-dom'
import './Form.scss'
import {injectShopContainer} from '../../Context/ShopData'


function Form ({settings={}}) {
  const {enableOrdering} = settings
  return(
    <div className="form-wrapper-contact">
      <form name="reserve" method="POST" data-netlify="true">
        <h2>Reserve</h2>

        

        <p>This is a temporary first come first serve reservations form. you will be emailed a confirmation within 12hrs. Spot not guaranteed until we email you.</p>

        <p>Automated reservations system will be up soon™️</p>
        {/* <p>Sign up to be notified when we open</p> */}


        <input type="hidden" name="form-name" value="reserve" />
        <p>
          <label>Your Name: <input type="text" name="name" /></label>
        </p>
        <p>
          <label>Your Email: <input type="email" name="email" /></label>
        </p>
        <p>
          <label>Your Phone: <input type="tel" name="phone" /></label>
        </p>
        <p>
          <label>Party Size: <input type="text" name="size" /></label>
        </p>
        <p>
          <label>Date: (Open Wed-Sun starting Jan 29th) <input type="text" name="date" /></label>
        </p>
        <p>
          <label>Time: (5pm-9:45pm) <input type="text" name="time" /></label>
        </p>
        {/* <p>
          <label>Optional Zipcode: (to help us plan our locations)
          <br/><input type="zipcode" name="zipcode" /></label>
        </p> */}
        {/* <p>
          <label>Message: <textarea name="message"></textarea></label>
        </p> */}
        <p>
          <button type="submit">Submit</button>
        </p>
      </form>
    </div>
  )
}

export default injectShopContainer(Form)