import React from 'react'
import {Link} from 'react-router-dom'
import './Form.scss'
import {injectShopContainer} from '../../Context/ShopData'


function Form ({settings={}}) {
  const {enableOrdering} = settings
  return(
    <div className="form-wrapper-contact">
      <form name="contact" method="POST" data-netlify="true">
        <h2>Mailing List</h2>

        

        <p>Subscribe for reservations, updates and promotions</p>
        {/* <p>Sign up to be notified when we open</p> */}


        <input type="hidden" name="form-name" value="contact" />
        <p>
          <label>Your Name: <input type="text" name="name" /></label>
        </p>
        <p>
          <label>Your Email: <input type="email" name="email" /></label>
        </p>
        {/* <p>
          <label>Optional Zipcode: (to help us plan our locations)
          <br/><input type="zipcode" name="zipcode" /></label>
        </p> */}
        {/* <p>
          <label>Message: <textarea name="message"></textarea></label>
        </p> */}
        <p>
          <button type="submit">Submit</button>
        </p>
      </form>
    </div>
  )
}

export default injectShopContainer(Form)