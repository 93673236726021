import React, { Component, PureComponent } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
import './index.scss'
import Headers from './Headers'

import LandingPage, {subMenuFilter} from './LandingPage'
import Butler from './Butler'
import Reservations from './ReservationsYelp'
// import OpenTable from './OpenTable'
// import ReservationsTmp from './ReservationsTmp'
import Form from './Form'
import FormReserve from './FormReserve'
import AutoMenu from './AutoMenu'


import './ThemeToggle/theme_night.scss'
import ThemeToggle from './ThemeToggle'

import CartBar from './CartBar'
import { injectShopContainer } from '../../Context/ShopData';
export {CartBar as StickyTopBar}

export const customOrderAppPaths = []


class TwoGuysFromItalyGlendale extends Component {
  render() {
    return (
      <div className="twoguysfromitalyglendale">
      </div>
    );
  }
}


export const pages = [
  // {path: "/order", label: "Order", component: injectShopContainer(OrderingHeader)},
  // {path: "/dinein", label: "Patio Ordering", hidden: true, component: Butler},
  // {path: "/reserve-tmp", label: "Reservations", component: ReservationsTmp},
  // {path: "/reserve", label: "Reservations", component: Reservations},
  // {path: "/reserve/:id/:name", label: "Reservations", hidden: true, component: Reservations},
  // {path: "/index.php", label: "Health", component: () => <Redirect to="/health" />, hidden: true},
  // {path: "/index.php?option=com_content&view=frontpage&Itemid=3", label: "Health", component: () => <Redirect to="/menu" />, hidden: true},
  // {path: "/health", label: "Health", component: Health},
  // {path: "/about", label: "About", component: OurStory},
  {path: "/menu",  label: "Menu", component: Menu},
  // {path: "/specials", label: "Specials", component: Specials},
  // {path: "/mothers", label: "Mothers", component: Mothers},
  // {path: "/drinks", label: "Drinks", component: Beverage},
  // {path: "/wine", label: "WineSale", component: Wine},
  // {path: "/catering", label: "Catering", component: Catering},
  // {path: "/wp-content/uploads/2018/05/MENU_NEW_012618.pdf", hidden: true, component: () => <Redirect to="/menu" />},
  // {path: "/catering", label: "Catering", component: Catering},
  // {path: "/about", label: "Our Story", component: OurStory},
]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, hidden, external}) => (
          hidden ? null : <li><NavLink className="page-link" to={path}>{label}</NavLink></li>
        ))}
      </ul>
      <ThemeToggle></ThemeToggle>
    </nav>
  )}
}

function Menu () {
  return(
    <div className="page">
      <div
          className="page-header">
            

        <div className="text">

            <h1>Menu</h1>
            <Headers></Headers>
            
          
        </div>
        
        </div>

        {/* <p
        style={{
          textAlign: 'center',
          padding: '1em 0.5em',
          fontWeight: 700
        }}
        >No Outside Drinks Allowed</p>         */}
      <AutoMenu
        // disableOrder={true}
        subMenuFilter={subMenuFilter}
      ></AutoMenu>  
    </div>
  )
}

export function OrderingHeader ({settings={}}) {
  const {enableOrdering} = settings

    return (
      <div className="page">
        <div
          className="page-header">

        <div className="text">
            <h1>Pickup / Delivery</h1>
            <Headers></Headers>
           
{/* 
          <p
          style={{
            margin: "1.5em 0",
            lineHeight: "1.5em"
          }}
          >🌮🇵🇪 $1 Taco Tuesday Special: <a href="https://pablitostacos.com/dtla">Order Pickup / Delivery</a> from Pablitos Tacos DTLA Truck, Parked in front of Mikaza.</p>
          <p>Pablitos Tacos serves Peruvian x Mexican Taco</p> */}
        </div>

          {/* <div className="logo-wrapper">
            <Stout className="stout-header"></Stout>
          </div> */}
        </div>
        
        {/* {!enableOrdering &&
          <Form></Form>} */}
        <AutoMenu
        subMenuFilter={subMenuFilter}
        ></AutoMenu>
      </div>
    );
  
}


// export { OrderingHeader: injectShopContainer(OrderingHeader) }

const sections = [
  {
    title: "Happy Hour",
    content: "Every Day 3pm-7pm. All Day Saturday",
    backgroundImage: ""
  }
]

// logo link: https://d2abh92eaqfsaa.cloudfront.net/image/upload/u7ryxhrj9dapgz8pqmny
export class Header extends PureComponent {
  render() {
    return (
      <div className="landing-page-wrapper">
        
      {/* <Headers></Headers> */}

      <Headers></Headers>
      <LandingPage></LandingPage>









      </div>
    );
  }
}

const foodImages = [
  'https://afag.imgix.net/two-guys-from-italy-glendale/meat-balls-pasta.jpg?w=800&auto=compress',
  'https://afag.imgix.net/two-guys-from-italy-glendale/porchiuto.jpg?w=800&auto=compress',
  'https://afag.imgix.net/two-guys-from-italy-glendale/pizza.jpg?w=800&auto=compress',
  'http://www.glendaletwoguysfromitaly.com/img/carousel/home_bg_d.jpg',
  'http://www.glendaletwoguysfromitaly.com/img/carousel/home_bg_e.jpg'
]

export default TwoGuysFromItalyGlendale;
