
import * as Components from "./Pablitospizza"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "pablitostacosnoho"
}

