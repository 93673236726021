import React, {useContext} from 'react'
import AutoMenu from './AutoMenu'
import {Link} from 'react-router-dom'
import Banner from './banner'
import LogoBlend from './svg/LogoBlend'
import LogoBar from './svg/LogoBar'
import Stout from './stout'
import Instagram from './icons/Instagram'
import Facebook from './icons/Facebook'
import Twitter from './icons/Twitter'
import './LandingPage.scss'
import Form from './Form'
import './WhatsNew.scss'
import ThemeContext from '../../Context/ThemeContext'
import FormReserve from './FormReserve'
import Headers from './Headers'



const youtubeReview = "https://www.youtube.com/embed/NeZIUbKfKPE"


const menuLinks = {
  walnut: [
    {name: "Family Meals TO GO",      link: '/public/walnut-creek/v4-family.pdf'},
    {name: "Meals Instructions",      link: '/public/walnut-creek/bierhaus-family-togo-instructions.pdf'},
    // {name: "Lunch Menu",      link: '/public/walnut-creek/v17%20WC%20Bierhaus%20Lunch%20Menu.pdf'},
    // {name: "Dinner Menu",     link: '/public/walnut-creek/v17%20WC%20Bierhaus%20Dinner%20Menu.pdf'},
    // // {name: "Happy Hour Menu", link: '/public/walnut-creek/v2%20WC%20Happy%20Hour%20Menu.pdf'},
    // {name: "Bier/Wine Menu",  link: '/public/walnut-creek/v16%20WC%20Beer%20Menu.pdf'},
    {name: "Beers TO GO",  link: '/public/walnut-creek/v3-Bottle-Menu.pdf'}
  ],
  oakland: [
    // {name: "Daily Menu",  link: '/public/oakland/v6-OAK-Limited-Bierhaus-Food-Menu.pdf'},
    // {name: "Happy Hour Menu",  link: '/public/oakland/L4%20Happy%20Hour%20Menu.pdf'},
    // {name: "Bier/Wine Menu",  link: '/public/oakland/v21-OAK-Beer-Menu.pdf'}
  ]
}

const gallery = [
  // {
  //   alt: '',
  //   url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-team.jpg'
  // },

  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-1.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-2.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-3.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-4.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-5.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-6.png'
  },

  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-team-1.jpg'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-team-2.jpg'
  },
]

export default function LandingPage () {
  const {themeName} = useContext(ThemeContext)
  const logoURL = (themeName === 'dark') ?
  "https://afag.imgix.net/pablitos-pizza/logo-large.png?w=900&auto=format" :
  "https://afag.imgix.net/pablitos-pizza/logo-large.png?w=900&auto=format"
  return(
    <div className="landing-page">
      <Headers></Headers>
      
      <div className="header">
        <div className="hero-wrapper">
          <div className="hero-banner">

            <div>
              {/* <p
                style={{
                  color: 'white',
                  textShade: "2px -2px 1px black",
                  fontWeight: 800
                }}
                >Order Curbside / Pickup / Delivery</p> */}
              {/* <a href="http://toasttab.com/bierhaus-walnut-creek" className="order-online-button">Order Pickup</a> */}
              {/* <Link to="/order" className="order-online-button">
              Order Pickup / Delivery
              <br/>
              <div
                style={{
                  fontSize: "0.8em",
                  marginTop: "0.2em",
                  textAlign: "center"
                }}
                className="small">10% Cashback</div>
            </Link>
            
              <Link to="/reserve" className="order-online-button">
              Reserve
              
            </Link> */}
{/* 
            <Link to="/order" className="order-online-button">Order Pickup / Delivery</Link>
            <br />
            <a href="https://pablitosworld.com" className="order-online-button">new - Chinatown Location</a> */}
            
            </div>
          </div>
          <div className="logo-banner">
            <img src={logoURL}
             alt="Pablito's Pizza"
             className="hero-logo"
           />
            {/* <LogoBlend
              themeName={themeName}
              className="hero-logo"
              ></LogoBlend> */}


          </div>
        </div>


      </div>

      <div className="content">

        {/* <p
          style={{
            textAlign: 'center',
            margin: '1.5em 0.5em',
            fontWeight: "600",

          }}
          className="notice">NOTICE: We may have to close early afternoon so get in your order now</p> */}

        <div className="content-grid locations">
          <article className="location walnut">
            <div className="location-header">
              <h2 className="location-name">1. North Hollywood</h2>

              {/* <p className="subtitle">North Hollywood</p> */}

              <Link to="/order" className="order-online-button">Order Pickup / Delivery</Link>

              <p className="address">
                <a className="address-link"
                  href="https://www.google.com/maps/place/5600+Vineland+Ave,+North+Hollywood,+CA+91601/@34.1724478,-118.3720834,17z/data=!3m1!4b1!4m5!3m4!1s0x80c295ecd3d725a3:0x9552fdc2c95bcefa!8m2!3d34.1724434!4d-118.3698894">
                  5600 Vineland Ave, North Hollywood
                </a></p>
              <p className="phone">
                 <a
                   style={{fontWeight: 600}}
                   href="tel:+18189668534‬">(818) 966-8535‬</a>
              </p>



              <div className="hours">
                <h3 className="hours-title">Pickup / Delivery</h3>
                
                <p>Mon-Thu</p>
                <p>5pm-12am</p>
                <p>Fri,sat</p>               
                <p>5pm-1am</p>
                

        
              </div>
            </div>


          </article>

         






        </div>


      </div>

      <div className="whats-new">

       


        <Form></Form>

        {/* <div className="youtube-vid-wrapper">
          <iframe
          className="youtube-vid"
          width="560"
          height="315"
          src={youtubeReview}
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          ></iframe>
      </div>


        <div className="gallery">
          {gallery.map(({url, alt}) => {
            return (
              <img
               alt="sample food photo of Mikaza, menu coming later"
               className={"gallery-img"}
               src={`${url}?fit=crop&w=400&h=400`} alt=""/>
            )
          })}
        </div> */}

{/* 
        <div className="whats-new__content">

          <article className="full-spread slow cooked">

            <div className="main">
              <img src="https://afag.imgix.net/bierhaus/ribs-close-up.jpg?w=1200&auto=format" alt=""/>
            </div>

            <div className="text-box-wrapper">
                <div className="text-box">
                <h2>Slow-Cooked</h2>
                <p>Available warm or as reheat-at-home meal kits.</p>
              </div>
            </div>

          </article>



        </div> */}
{/*
        <div className="link-wrapper">
          <a href="/order?bottom" className="order-online-button">View Full Menu</a>
        </div> */}

      </div>

      <div className="menu">
        {/* <div className="menu-header">
          <h2>Our Menu</h2>
        </div> */}

        <AutoMenu 
        subMenuFilter={subMenuFilter}
        width={600}></AutoMenu>

        {/* <div className="beverage-menu-link-wrapper">
          <Link to="/drinks" className="order-online-button">Full Beverage Menu</Link>
        </div> */}
      </div>



      {/* <div className="content">
        <div className="content-grid">
          <div className="email-form">
            <EmailSubscribeForm></EmailSubscribeForm>
          </div>


          <div className="social-links">
            <a
              className="social-link"
              href="https://www.instagram.com/bierhausca/"><Instagram></Instagram>Instagram</a>
            <a
              className="social-link"
              href="https://www.facebook.com/bierhausca/"><Facebook></Facebook>Facebook</a>
            <a
              className="social-link"
              href="https://twitter.com/BierhausCA"><Twitter></Twitter>Twitter</a>
          </div>
        </div>
      </div> */}






      {/* <div className="landing-page-footer">
        <Stout></Stout>
      </div> */}
      {/* <Headers></Headers> */}
    </div>
  )
}


export function subMenuFilter (subMenu) {
  const tag = subMenu.tag || ''
  // if (!tag) {return true}

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  // const isWeekday = [1,2,3,4].indexOf(day) !== -1
  // const isLunch = (
  //   (hours >= 11) && (hours < 22)
  // )
  // const isBreakfast = (
  //   (hours < 15)
  // )

  if (tag.indexOf('pizza') !== -1 ) {
    return true
  }
 

  return false
}
