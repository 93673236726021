import React from "react";
import {Helmet} from "react-helmet";
// import {injectShopContainer} from '../../react/ShopDataContainer'


export default function () {
  let name = "Pablitos Pizza"
    let description = "Neopolitan Mexican Pizza by Pablito's"
    return (
      <Helmet>
          <meta charSet="utf-8" />
          <title>{name}</title>
          <meta name="description"
            content={description} />
          <link rel="canonical" href="https://pablitospizza.com" />

          <link rel="manifest" href={`${process.env.PUBLIC_URL}/pablitospizza.json`} />
          <link rel="apple-touch-icon" sizes="250x250"
            href={`${process.env.PUBLIC_URL}/pablitospizza.png`} />
          <link rel="apple-touch-icon" sizes="250x250"
            href={`${process.env.PUBLIC_URL}/pablitospizza.png`} />
          <link rel="shortcut icon"
            href={`${process.env.PUBLIC_URL}/pablitospizza.png`}
          />

          {/* Open Graph */}
          <meta property="og:title" content={name} />
          <meta property="og:site_name" content={name} />
          <meta property="og:description" content={description} />
          {/* <meta property="og:image" content={} /> */}
          {/* <meta property="og:url" content="http://euro-travel-example.com/index.htm" /> */}
      </Helmet>


    );
} 
